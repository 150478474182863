<template>
  <div class="contact">
    
    <h3>Me contacter</h3>
    <div class="contact-container">
      <p>
        <span>
          <i class="fas fa-envelope"></i>
        </span>
        <a href="mailto:guillaume.lepoetre@gmail.com"> guillaume.lepoetre@gmail.com </a>
      </p>
      <p>
        <span>
          <i class="fas fa-phone"></i>
        </span>
        <a href="tel:+33678901234">06 60 45 96 90</a>
      </p>
      <p>
        <span>
          <i class="fas fa-map-marker-alt"></i>
        </span>
        <a href="https://www.google.com/maps/place/60240+Chaumont-en-Vexin/@49.273305,1.3225275,9z/data=!4m5!3m4!1s0x47e6e3886737a127:0x1cedb5841854ff88!8m2!3d49.2621619!4d1.882324">60240 Chaumont-en-Vexin</a><small> ( Disponible en full remote )</small>
      </p>
      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d625291.8480033185!2d1.7865986110902532!3d49.37560231217269!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e6e3886737a127%3A0x1cedb5841854ff88!2s60240%20Chaumont-en-Vexin!5e0!3m2!1sfr!2sfr!4v1639994527368!5m2!1sfr!2sfr"  allowfullscreen="" loading="lazy"></iframe>
      <p>
        <span>
          <i class="fab fa-linkedin"></i>
        </span>
        <a href="https://www.linkedin.com/in/guillaume-lepoetre-b8a8b817b/" target="_blank">LinkedIn</a>
      </p>
      <p>
        <span>
          <i class="fab fa-github"></i>
        </span>
        <a href="https://github.com/guillaume60240" target="_blank">Github</a>
      </p>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  data() {
    return {
      
    };
  },
  computed: mapState({
    files: state => state.files,
    api: state => state.api,
  }),
  mounted() {
    document.title = 'Me contacter - Portfolio de Guillaume Lepoetre'
  }

}
</script>

<style scoped>
  .contact {
    margin: 0 auto;
  }
  .contact h3 {
    padding: 1.5em;
    background: #3333;
    max-width: 1200px;
    margin: 0 auto;
  }
  .contact-container {
    display: flex;
    flex-direction: column;
    max-width: 1200px;
    margin: 0 auto;
    margin-bottom: 1rem;
    text-align: left;
    padding: 2rem;
  }
  .contact-container p {
    margin-bottom: 0.5rem;
  }
  .contact-container p span {
    font-size: 1.5rem;
    margin-right: 0.5rem;
  }
  .contact-container p a {
    color: #000;
    text-decoration: none;
  }
  .contact-container p a:hover {
    text-decoration: underline;
  }
  .contact-container iframe {
    width: 100%;
    height: 300px;
    border: none;
  }
  .link-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }
  .link-container a {
    color: #000;
    text-decoration: none;
    margin-right: 1rem;
  }
  .link-container a i {
    font-size: 1.5rem;
  }
  .link-container a:hover {
    text-decoration: underline;
  }


</style>